@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
    -webkit-tap-highlight-color: transparent;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

    -webkit-overflow-scrolling: touch;

    -moz-osx-font-smoothing: grayscale;

    -webkit-font-smoothing: antialiased;
}

#root {
    height: 100%;
}

.e-upload-actions {
    display: none;
}

.theme-color {
    background-color: rgba(102, 51, 153, 1);
    color: white;
}

.theme-color-button {
    background-color: rgba(102, 51, 153, 1);
    color: white;
}

.theme-color-button:hover {
    background-color: rgba(102, 51, 153, 1);
    color: white;
    cursor: pointer;
}

.theme-color-text {
    color: rgba(102, 51, 153, 1);
}

.theme-scrollbar {
    --scrollbar-thumb: rgba(102, 51, 153, 1) !important;
}

.theme-scrollbar-track {
    --scrollbar-track: rgb(225, 196, 255) !important;
}

.list-item-text {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

#pdf-div {
    height: 320px;
    max-height: 320px;
    width: auto;
}

.mentionItem {
    padding: 5px 15px 5px 15px;
}

.mentionItem:hover {
    background-color: #fbf9f9;
    cursor: pointer;
}

.mentionDropdown {
    max-height: 225px;
    overflow-y: auto;
}